import React from 'react'
import styled from "styled-components"

const ComingSoonButton = styled.div`
  border: 1px solid #1d1d1d;
  box-shadow: none;
  font-family: PragmaticaCondBook;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  font-size: 40px;
  color: #1d1d1d;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const Placeholder = () => {
  return (
    <ComingSoonButton>
      PORTFOLIO SITE COMING SOON
      <span style={{ fontFamily: 'PPEditorialNewItalics' }}>ish</span>
      !!!
    </ComingSoonButton>
  );
}

export default Placeholder